import {EnhancedMenu, useIsHomePath} from '~/lib/utils';
import {Drawer, useDrawer} from './Drawer';
import {useCartFetchers} from '~/hooks/useCartFetchers';
import {ReactNode, Suspense, useEffect, useMemo} from 'react';
import {Await, useMatches, useParams} from '@remix-run/react';
import {CartLoading} from './CartLoading';
import {Cart} from './Cart';
import {Link} from './Link';
import {Heading, Section, Text} from './Text';
import {IconAccount, IconBag, IconLogin, IconMenu} from './Icon';
import {useLocation, useWindowScroll} from 'react-use';
import {useIsHydrated} from '~/hooks/useIsHydrated';
import {LayoutData} from '~/root';
import {CountrySelector} from './CountrySelector';
import ComingSoon from './ComingSoon';
import logoWhite from '../../public/vv_logo_letter_white.png';

export function Layout({
  children,
  layout,
}: {
  children: ReactNode;
  layout: LayoutData;
}) {
  const location = useLocation();

  return (
    <>
      {location.search !== '?backdoor=dev_open' && <ComingSoon />}
      {location.search === '?backdoor=dev_open' && (
        <>
          <div className="max-w-[90rem] mx-auto border-l border-r border-gray-300">
            <div className="relative bg-offBlack h-full w-full border-8 border-offWhite">
              <div className="h-full mx-auto max-w-[90rem]">
                <Header menu={layout?.headerMenu} />
              </div>
              <main
                role="main"
                id="mainContent"
                className="flex-grow min-h-[500px]"
              >
                {children}
              </main>
            </div>
          </div>
          <Footer menu={layout?.footerMenu} />
        </>
      )}
    </>
  );
}

function Header({menu}: {menu?: EnhancedMenu}) {
  const isHome = useIsHomePath();
  const {
    isOpen: isCartOpen,
    openDrawer: openCart,
    closeDrawer: closeCart,
  } = useDrawer();

  const {
    isOpen: isMenuOpen,
    openDrawer: openMenu,
    closeDrawer: closeMenu,
  } = useDrawer();

  const addToCartFetchers = useCartFetchers('ADD_TO_CART');

  // Toggle cart drawer when adding to cart
  useEffect(() => {
    if (isCartOpen || !addToCartFetchers.length) return;
    openCart();
  }, [addToCartFetchers, isCartOpen, openCart]);

  return (
    <>
      <CartDrawer isOpen={isCartOpen} onClose={closeCart} />
      <MenuDrawer menu={menu} isOpen={isMenuOpen} onClose={closeMenu} />
      <DesktopHeader isHome={isHome} openMenu={openMenu} openCart={openCart} />
      <MobileHeader isHome={isHome} openCart={openCart} openMenu={openMenu} />
    </>
  );
}

function CartDrawer({isOpen, onClose}: {isOpen: boolean; onClose: () => void}) {
  const [root] = useMatches();

  return (
    <Drawer open={isOpen} onClose={onClose} heading="Cart" openFrom="right">
      <div className="grid">
        <Suspense fallback={<CartLoading />}>
          <Await resolve={root.data?.cart}>
            {(cart) => <Cart layout="drawer" onClose={onClose} cart={cart} />}
          </Await>
        </Suspense>
      </div>
    </Drawer>
  );
}

export function MenuDrawer({
  isOpen,
  onClose,
  menu,
}: {
  isOpen: boolean;
  onClose: () => void;
  menu: EnhancedMenu;
}) {
  return (
    <Drawer open={isOpen} onClose={onClose} openFrom="left" heading="Menu">
      <div className="grid">
        <MenuMobileNav menu={menu} onClose={onClose} />
      </div>
    </Drawer>
  );
}

function MenuMobileNav({
  menu,
  onClose,
}: {
  menu: EnhancedMenu;
  onClose: () => void;
}) {
  const [root] = useMatches();
  const isLoggedIn = root.data?.isLoggedIn;

  return (
    <nav
      id="vv_sidebar"
      className="flex flex-col items-center gap-2 p-6 pt-0 px-8"
    >
      {/* Shop Section */}
      <p className="text-gray-500 text-xs">Shop</p>
      <ul className="mb-10 flex flex-col items-center">
        {(menu?.items || []).map((item) => (
          <li key={item.id}>
            <Link
              to={item.to}
              target={item.target}
              onClick={onClose}
              className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
            >
              <Text as="span" size="copy">
                {item.title}
              </Text>
            </Link>
          </li>
        ))}
      </ul>
      {/* Customer Care Section */}
      <p className="text-gray-500 text-xs">Customer Care</p>
      <ul className="mb-10 flex flex-col items-center">
        <li key="sidebar-tos">
          <Link
            className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
            to="/faq"
            onClick={onClose}
          >
            FAQ
          </Link>
        </li>
        <li key="sidebar-tos">
          <Link
            className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
            to="/measurements"
            onClick={onClose}
          >
            How To Get Your Measurements
          </Link>
        </li>
        <li key="sidebar-privacy">
          <Link
            className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
            to="/policies/refund-policy"
            onClick={onClose}
          >
            Returns & Refunds Policy
          </Link>
        </li>
        <li key="sidebar-privacy">
          <Link
            className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
            to="/policies/shipping-policy"
            onClick={onClose}
          >
            Shipping Policy
          </Link>
        </li>
      </ul>
      {/* Brand Section */}
      <p className="text-gray-500 text-xs">Brand</p>
      <ul className="mb-10 flex flex-col items-center">
        <li key="sidebar-tos">
          <Link
            className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
            to="/contact"
            onClick={onClose}
          >
            Contact Us
          </Link>
        </li>
        <li key="sidebar-tos">
          <Link
            className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
            to="/policies/terms-of-service"
            onClick={onClose}
          >
            Terms of Service
          </Link>
        </li>
        <li key="sidebar-privacy">
          <Link
            className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
            to="/policies/privacy-policy"
            onClick={onClose}
          >
            Privacy Policy
          </Link>
        </li>
      </ul>
      {/* Account Section */}
      <p className="text-gray-500 text-xs">Account</p>
      {!isLoggedIn && (
        <ul className="mb-10 flex flex-col items-center">
          <li key="sidebar-signin">
            <Link
              className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
              to="/account/login"
              onClick={onClose}
            >
              Sign In
            </Link>
          </li>
          <li key="sidebar-register">
            <Link
              className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
              to="/account/register"
              onClick={onClose}
            >
              Create an account
            </Link>
          </li>
        </ul>
      )}
      {isLoggedIn && (
        <ul className="mb-10 flex flex-col items-center">
          <li key="sidebar-account">
            <Link
              className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
              to="/account"
              onClick={onClose}
            >
              My account
            </Link>
          </li>
          <li key="sidebar-logout">
            <Link
              className="hover:text-primary text-xs uppercase font-semibold whitespace-nowrap"
              to="/account/logout"
              onClick={onClose}
            >
              Log out
            </Link>
          </li>
        </ul>
      )}
      <div className="w-max m-auto">
        <a
          href="https://www.instagram.com/officialvisvitalis"
          target="_blank"
          rel="noreferrer"
          className="inline-block mx-2 w-5 h-5"
        >
          <InstagramIcon className="hover:fill-primary" />
        </a>
        <a
          href="https://www.twitter.com/vitalisofficial"
          target="_blank"
          rel="noreferrer"
          className="inline-block mx-2 w-5 h-5"
        >
          <TwitterIcon className="hover:fill-primary" />
        </a>
      </div>
    </nav>
  );
}

function MobileHeader({
  isHome,
  openCart,
  openMenu,
}: {
  isHome: boolean;
  openCart: () => void;
  openMenu: () => void;
}) {
  return (
    <header
      role="banner"
      className="text-white h-nav lg:hidden z-40 sticky top-0"
    >
      <div className="h-20 px-4 py-3 flex">
        {/* Hamburger Men */}
        <div className="flex items-center justify-start w-full gap-4">
          <button
            onClick={openMenu}
            className="hover:underline text-offWhite flex justify-center items-center w-max h-full"
          >
            <IconMenu color="white" />
            <span className="hidden sm:inline-block text-xs ml-2 font-bold text-offWhite uppercase translate-y-[1px]">
              Menu
            </span>
          </button>
        </div>
        {/* Logo */}
        <Link className="px-4 absolute left-[50%] translate-x-[-50%]" to="/">
          <img
            className="object-contain"
            src={logoWhite}
            width={90}
            height={65}
          />
        </Link>
        {/* Account & Cart */}
        <div className="flex items-center gap-0">
          <AccountLink className="relative flex items-center justify-center w-8 h-5 focus:ring-primary/5" />
          <CartCount isHome={isHome} openCart={openCart} />
        </div>
      </div>
    </header>
  );
}

function DesktopHeader({
  isHome,
  openMenu,
  openCart,
}: {
  isHome: boolean;
  openMenu: () => void;
  openCart: () => void;
}) {
  return (
    <header
      role="banner"
      className="text-white h-nav hidden lg:block z-40 sticky top-0"
    >
      <div className="h-20 px-4 py-3 flex">
        {/* Hamburger Menu */}
        <div className="flex items-center justify-start w-full gap-4">
          <button
            onClick={openMenu}
            className="hover:underline text-offWhite flex justify-center items-center w-max h-full"
          >
            <IconMenu color="white" />
            <span className="hidden sm:inline-block text-xs ml-2 font-bold text-offWhite uppercase translate-y-[1px]">
              Menu
            </span>
          </button>
        </div>
        {/* Logo */}
        <Link className="px-4 absolute left-[50%] translate-x-[-50%]" to="/">
          <img
            className="object-contain"
            src={logoWhite}
            width={90}
            height={65}
          />
        </Link>
        {/* Account & Cart */}
        <div className="flex items-center gap-0">
          <AccountLink className="relative flex items-center justify-center w-8 h-5 focus:ring-primary/5" />
          <CartCount isHome={isHome} openCart={openCart} />
        </div>
      </div>
    </header>
  );
}

function AccountLink({className}: {className?: string}) {
  const [root] = useMatches();
  const isLoggedIn = root.data?.isLoggedIn;

  return isLoggedIn ? (
    <Link to="/account" className={className}>
      <IconAccount className="h-6 w-6 mt-1" />
    </Link>
  ) : (
    <Link to="/account/login" className={className}>
      <IconLogin className="h-6 w-6 mt-1" />
    </Link>
  );
}

function CartCount({
  isHome,
  openCart,
}: {
  isHome: boolean;
  openCart: () => void;
}) {
  const [root] = useMatches();

  return (
    <Suspense fallback={<Badge count={0} dark={isHome} openCart={openCart} />}>
      <Await resolve={root.data?.cart}>
        {(cart) => (
          <Badge
            dark={isHome}
            openCart={openCart}
            count={cart?.totalQuantity || 0}
          />
        )}
      </Await>
    </Suspense>
  );
}

function Badge({
  openCart,
  dark,
  count,
}: {
  count: number;
  dark: boolean;
  openCart: () => void;
}) {
  const isHydrated = useIsHydrated();
  const BadgeCounter = useMemo(
    () => (
      <>
        <IconBag />
        <div
          className={`${
            dark
              ? 'text-primary bg-contrast dark:text-contrast dark:bg-primary'
              : 'text-contrast bg-primary'
          } absolute -bottom-1 right-1 text-[0.625rem] font-medium subpixel-antialiased h-3 min-w-[0.75rem] flex items-center justify-center leading-none text-center rounded-full w-auto px-[0.125rem] pb-px`}
        >
          <span>{count || 0}</span>
        </div>
      </>
    ),
    [count, dark],
  );

  return isHydrated ? (
    <button
      onClick={openCart}
      className="relative flex items-center justify-center w-8 h-5 focus:ring-primary/5"
    >
      {BadgeCounter}
    </button>
  ) : (
    <Link
      to="/cart"
      className="relative flex items-center justify-center w-8 h-5 focus:ring-primary/5"
    >
      {BadgeCounter}
    </Link>
  );
}

function Footer({menu}: {menu?: EnhancedMenu}) {
  const isHome = useIsHomePath();
  const [root] = useMatches();
  const isLoggedIn = root.data?.isLoggedIn;
  const itemsCount = menu
    ? menu?.items?.length + 1 > 4
      ? 4
      : menu?.items?.length + 1
    : [];

  return (
    <Section
      style={{padding: '0px'}}
      divider={isHome ? 'none' : 'top'}
      as="footer"
      role="contentinfo"
    >
      <div className="relative text-xs bg-offWhite border-t border-gray-300 w-full max-w-[90rem] mx-auto">
        <div className="mx-auto max-w-[90rem] px-4 py-10 md:px-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12">
          {/* Account Section */}
          <div>
            <h3 className="font-bold uppercase mb-4">Account</h3>
            {!isLoggedIn && (
              <ul className="text-gray-500 mt-4 space-y-2">
                <li key="footer_signin">
                  <Link className="hover:text-primary" to="/account/login">
                    Sign In
                  </Link>
                </li>
                <li key="footer_register">
                  <Link className="hover:text-primary" to="/account/register">
                    Create An Account
                  </Link>
                </li>
              </ul>
            )}
            {isLoggedIn && (
              <ul className="text-gray-500 mt-4 space-y-2">
                <li key="footer_signin">
                  <Link className="hover:text-primary" to="/account">
                    Account
                  </Link>
                </li>
                <li key="footer_register">
                  <Link className="hover:text-primary" to="/account/logout">
                    Log out
                  </Link>
                </li>
              </ul>
            )}
          </div>

          {/* Collection Section */}
          <div>
            <h3 className="font-bold uppercase mb-4">Collections</h3>
            <ul className="text-gray-500 mt-4 space-y-2"></ul>
          </div>

          {/* Customer Care Section */}
          <div>
            <h3 className="font-bold uppercase mb-4">Customer Care</h3>
            <ul className="text-gray-500 mt-4 space-y-2">
              <li key="faq">
                <Link className="hover:text-primary" to="/faq">
                  FAQ
                </Link>
              </li>
              <li key="measurements">
                <Link className="hover:text-primary" to="/measurements">
                  How To Get Your Measurements
                </Link>
              </li>
              <li key="policy_refund">
                <Link
                  className="hover:text-primary"
                  to="/policies/refund-policy"
                >
                  Returns & Refunds Policy
                </Link>
              </li>
              <li key="policy_shipping">
                <Link
                  className="hover:text-primary"
                  to="/policies/shipping-policy"
                >
                  Shipping Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Brand Section */}
          <div>
            <h3 className="font-bold uppercase mb-4">Brand</h3>
            <ul className="text-gray-500 mt-4 space-y-2">
              <li key="policy_contact">
                <Link className="hover:text-primary" to="/contact">
                  Contact Us
                </Link>
              </li>
              <li key="policy_terms">
                <Link
                  className="hover:text-primary"
                  to="/policies/terms-of-service"
                >
                  Terms Of Service
                </Link>
              </li>
              <li key="policy_privacy">
                <Link
                  className="hover:text-primary"
                  to="/policies/privacy-policy"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Socials */}
          <div>
            <h3 className="font-bold uppercase mb-4">Socials</h3>
            <ul>
              <li className="inline-block">
                <Link
                  to="https://www.instagram.com/officialvisvitalis"
                  target="_blank"
                  rel="noreferrer"
                  className="w-min flex items-center"
                >
                  <InstagramIcon className="hover:fill-primary" />
                </Link>
              </li>
              <li className="inline-block">
                <Link
                  to="https://www.twitter.com/vitalisofficial"
                  target="_blank"
                  rel="noreferrer"
                  className="w-min flex items-center"
                >
                  <TwitterIcon className="hover:fill-primary" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center text-sm py-5">© 2023 Vis Vitalis LLC</div>
      </div>
    </Section>
  );
}

function TwitterIcon({className}: {className: string}) {
  return (
    <svg
      className={`mr-3 fill-[#4B5563] ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 500 500"
    >
      <path d="m492 110c-13 18-29 35-49 50c0 0 0 12 0 12c0 44-10 87-30 128c-21 41-53 76-96 104c-43 28-92 42-148 42c-55 0-104-14-149-43c5 1 13 1 24 1c45 0 85-13 120-40c-21-1-40-8-56-20c-17-12-28-28-34-48c3 1 9 2 17 2c9 0 18-1 26-3c-23-5-41-16-56-34c-14-18-21-38-21-61c0 0 0-1 0-1c12 6 27 11 43 12c-29-20-43-47-43-81c0-16 4-32 13-48c53 64 119 98 200 100c-2-6-3-13-3-21c0-27 9-50 28-68c19-19 42-28 69-28c28 0 51 9 70 29c20-4 41-11 61-22c-7 22-21 40-42 53c19-3 38-8 56-15" />
    </svg>
  );
}

function InstagramIcon({className}: {className: string}) {
  return (
    <svg
      className={`mr-3 fill-[#4B5563] ${className}`}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 551.034 551.034"
    >
      <path
        className="logo"
        id="XMLID_17_"
        d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"
      />

      <path
        id="XMLID_81_"
        d="M275.517,133C196.933,133,133,196.933,133,275.516 s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6 c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083 C362.6,323.611,323.611,362.6,275.517,362.6z"
      />

      <circle id="XMLID_83_" cx="418.306" cy="134.072" r="34.149" />
    </svg>
  );
}
