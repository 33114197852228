import logoBlack from '../../public/vv_logo_letter_black.png';

export default function ComingSoon() {
  return (
    <div className="min-h-[500px] w-full h-screen flex justify-center items-center flex-col bg-offWhite py-20">
      <img src={logoBlack} width={150} height={150} />

      <h1 className="mt-10 font-bold uppercase text-md md:text-lg 2xl:text-xl">
        Coming Soon
      </h1>
    </div>
  );
}
